"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Scrollbar',
  props: {
    scrollContainerSelector: {
      type: String,
      default: '.el-table__body-wrapper'
    }
  },
  data: function data() {
    return {
      thumbWidth: 0,
      // Largeur du thumb
      thumbPosition: 0,
      // Position du thumb
      contentWidth: 0,
      // Largeur totale du contenu
      containerWidth: 0,
      // Largeur visible du conteneur
      isDragging: false,
      // État de drag
      startDragX: 0,
      // Position initiale du drag
      startThumbX: 0 // Position initiale du thumb

    };
  },
  mounted: function mounted() {
    var _this = this;

    var scrollContainer = document.querySelector(this.scrollContainerSelector);

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', this.syncThumbPosition);
      window.addEventListener('resize', this.updateScrollbar);
    }

    setTimeout(function () {
      _this.updateScrollbar();
    }, 500); // pour attendre que le DOM soit chargé
  },
  beforeDestroy: function beforeDestroy() {
    var scrollContainer = document.querySelector(this.scrollContainerSelector);

    if (scrollContainer) {
      scrollContainer.removeEventListener('scroll', this.syncThumbPosition);
      window.removeEventListener('resize', this.updateScrollbar);
    }
  },
  methods: {
    updateScrollbar: function updateScrollbar() {
      var scrollContainer = document.querySelector(this.scrollContainerSelector);

      if (!scrollContainer) {
        console.error('Scroll container not found:', this.scrollContainerSelector);
        return;
      }

      this.contentWidth = scrollContainer.scrollWidth; // Largeur totale du contenu

      this.containerWidth = scrollContainer.offsetWidth; // Largeur visible du conteneur

      var scrollbarWidth = document.querySelector('.custom-scrollbar').offsetWidth; // Largeur de la barre
      // Calculer la largeur du thumb

      var thumbWidth = this.containerWidth / this.contentWidth * scrollbarWidth;
      this.thumbWidth = Math.max(50, Math.min(thumbWidth, scrollbarWidth)); // Fixer une largeur minimale et éviter de dépasser
      // Synchroniser la position initiale du thumb

      this.syncThumbPosition();
    },
    syncThumbPosition: function syncThumbPosition() {
      var scrollContainer = document.querySelector(this.scrollContainerSelector);
      if (!scrollContainer) return;
      var scrollLeft = scrollContainer.scrollLeft;
      var scrollbarWidth = document.querySelector('.custom-scrollbar').offsetWidth; // Calculer la position proportionnelle du thumb

      var scrollRatio = scrollLeft / (this.contentWidth - this.containerWidth);
      this.thumbPosition = scrollRatio * (scrollbarWidth - this.thumbWidth);
    },
    startDrag: function startDrag(event) {
      this.isDragging = true;
      this.startDragX = event.clientX;
      this.startThumbX = this.thumbPosition;
      document.body.style.userSelect = 'none';
      window.addEventListener('mousemove', this.onThumbDrag);
      window.addEventListener('mouseup', this.stopDrag);
    },
    onThumbDrag: function onThumbDrag(event) {
      if (!this.isDragging) return;
      var deltaX = event.clientX - this.startDragX;
      var scrollbarWidth = document.querySelector('.custom-scrollbar').offsetWidth;
      var newThumbPosition = this.startThumbX + deltaX; // Limiter la position du thumb

      newThumbPosition = Math.max(0, Math.min(newThumbPosition, scrollbarWidth - this.thumbWidth));
      this.thumbPosition = newThumbPosition; // Synchroniser le défilement du conteneur

      var scrollRatio = this.thumbPosition / (scrollbarWidth - this.thumbWidth);
      var scrollContainer = document.querySelector(this.scrollContainerSelector);
      scrollContainer.scrollLeft = scrollRatio * (this.contentWidth - this.containerWidth);
    },
    stopDrag: function stopDrag() {
      this.isDragging = false;
      document.body.style.userSelect = 'auto';
      window.removeEventListener('mousemove', this.onThumbDrag);
      window.removeEventListener('mouseup', this.stopDrag);
    }
  }
};
exports.default = _default;