var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Année " + _vm._s(_vm.year))]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-bottom": "15px" },
          attrs: {
            data: _vm.dspData,
            size: "mini",
            height: "calc(100vh - 610px)"
          }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "num_affaire", label: "N° Affaire", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              scope.$index === 0 ||
                              (_vm.dspData[scope.$index - 1] &&
                                _vm.dspData[scope.$index].id !=
                                  _vm.dspData[scope.$index - 1].id),
                            expression:
                              "scope.$index === 0 || (dspData[scope.$index - 1] && dspData[scope.$index].id != dspData[scope.$index - 1].id)"
                          }
                        ],
                        attrs: { to: "/affaire/" + scope.row.id }
                      },
                      [
                        _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(_vm._s(scope.row.num_affaire))
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "designationDI",
              label: "Désignation DI",
              width: "200"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_vm._v(_vm._s(scope.row.designationDI))]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Avril " + _vm.year } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "Colonne1",
                  label: "Cumulé 01 à 04",
                  width: "120",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(_vm._s(_vm._f("toCurrency")(scope.row.Colonne1)))
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Août " + _vm.year } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "Colonne2",
                  label: "Cumulé 05 à 08",
                  width: "120",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(_vm._s(_vm._f("toCurrency")(scope.row.Colonne2)))
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table-column",
            { attrs: { label: "Décembre " + _vm.year } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "Colonne3",
                  label: "Cumulé 09 à 12",
                  width: "120",
                  align: "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(_vm._s(_vm._f("toCurrency")(scope.row.Colonne3)))
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "Total",
              label: "Total " + _vm.year,
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("toCurrency")(scope.row.Total)))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }