"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _moment = _interopRequireDefault(require("moment"));

var _vue = _interopRequireDefault(require("vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableauProvisions',
  props: ['data', 'date'],
  data: function data() {
    return {
      dspData: [],
      sums: []
    };
  },
  beforeMount: function beforeMount() {
    _moment.default.locale('fr');

    var prevId = 0;
    var id = 0;
    var nom = '';
    var mtTotalDebutExercice = 0;
    var mtTotalDotation = 0;
    var mtTotalReprise = 0;
    var mtTotalFinExercice = 0;
    var cpt = 1;

    for (var data in this.data) {
      id = this.data[data]['id'];
      if (prevId === 0) prevId = id;

      if (id > 0 && id !== prevId) {
        var stotal = {
          id: null,
          nom: nom,
          className: 'sous-total',
          mtTotalDebutExercice: mtTotalDebutExercice,
          mtTotalDotation: mtTotalDotation,
          mtTotalReprise: mtTotalReprise,
          mtTotalFinExercice: mtTotalFinExercice
        };
        this.dspData.push(stotal);
        nom = '';
        mtTotalDebutExercice = 0;
        mtTotalDotation = 0;
        mtTotalReprise = 0;
        mtTotalFinExercice = 0;
      } else {
        if (cpt > 1) {
          this.data[data].repetition = true;
        }
      }

      nom = this.data[data].nom;
      mtTotalDebutExercice = mtTotalDebutExercice + this.mtDebutExercice(this.data[data]);
      mtTotalDotation = mtTotalDotation + this.mtDotation(this.data[data]);
      mtTotalReprise = mtTotalReprise + this.mtReprise(this.data[data]);
      mtTotalFinExercice = mtTotalFinExercice + this.mtFinExercice(this.data[data]);
      prevId = id;
      cpt = cpt + 1; // --

      this.dspData.push(this.data[data]);

      if (cpt > this.data.length) {
        var _stotal = {
          id: null,
          nom: nom,
          className: 'sous-total',
          mtTotalDebutExercice: mtTotalDebutExercice,
          mtTotalDotation: mtTotalDotation,
          mtTotalReprise: mtTotalReprise,
          mtTotalFinExercice: mtTotalFinExercice
        };
        this.dspData.push(_stotal);
      }
    }
  },
  filters: {
    toSumMt: function toSumMt(value, column, aSums) {
      value = isNaN(value) ? 0 : Number(value);

      if (aSums[column] === undefined) {
        aSums[column] = Number(value);
      } else {
        aSums[column] += Number(value);
      }

      return value;
    }
  },
  methods: {
    mtDebutExercice: function mtDebutExercice(row) {
      return row.dotation_DEBUTEXERCICE - Math.abs(row.reprise_DEBUTEXERCICE);
    },
    mtDotation: function mtDotation(row) {
      return row.dotation_FINEXERCICE;
    },
    mtReprise: function mtReprise(row) {
      return Math.abs(row.reprise_FINEXERCICE);
    },
    mtFinExercice: function mtFinExercice(row) {
      return this.mtDebutExercice(row) + row.dotation_FINEXERCICE - Math.abs(row.reprise_FINEXERCICE);
    },
    getSummaries: function getSummaries(param) {
      var _this = this;

      var columns = param.columns;
      var sums = [];
      columns.forEach(function (column, index) {
        if (index === 2) {
          sums[index] = 'TOTAL GENERAL';
          return;
        }

        if (column.property !== undefined && ![0, 1, 2].includes(index)) {
          var value = _this.sums[column.property] ? _this.sums[column.property] : 0;

          if (column.property.substring(0, 2) === 'pc') {
            sums[index] = _vue.default.filter('toPercentage')(value);
          } else {
            sums[index] = _vue.default.filter('toCurrency')(value);
          }
        }
      });
      return sums;
    },
    rowClassName: function rowClassName(row) {
      // console.log(row, row.row.className)
      // on renvoit un nom de classe pour identifier la ligne de sous/total
      return row.row.className ? 'sous-total' : '';
    }
  }
};
exports.default = _default;