var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "data-tables",
        {
          ref: "dataTable",
          attrs: {
            layout: "tool, table",
            data: _vm.myData,
            "table-props": {
              defaultSort: { prop: "number", order: "ascending" }
            },
            total: _vm.total,
            "sort-method": _vm.sortMethod
          }
        },
        [
          _c("el-table-column", {
            key: _vm.columns["id"].key,
            attrs: {
              label: _vm.columns["id"].label,
              prop: _vm.columns["id"].prop,
              width: _vm.columns["id"].width,
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.id))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["name"].key,
            attrs: {
              label: _vm.columns["name"].label,
              prop: _vm.columns["name"].prop,
              width: _vm.columns["name"].width,
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.name))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["number"].key,
            attrs: {
              label: _vm.columns["number"].label,
              prop: _vm.columns["number"].prop,
              width: _vm.columns["number"].width,
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.number))])]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }