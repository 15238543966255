var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "synthese-list" },
    [
      _c(
        "el-collapse",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.activeFilter,
            callback: function($$v) {
              _vm.activeFilter = $$v
            },
            expression: "activeFilter"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: "filters" } },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticStyle: { width: "50%" } }, [
                  _vm._v("Synthèse générale")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "50%",
                      display: "inline",
                      "text-align": "right",
                      "margin-right": "15px"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: _vm.filterExists ? "danger" : "success",
                          size: "mini"
                        }
                      },
                      [_vm._v("Filtres")]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  staticStyle: { "white-space": "nowrap" },
                  attrs: { "label-width": "250px", size: "mini" }
                },
                [
                  _c(
                    "el-row",
                    { staticStyle: { margin: "10px 0 10px 0" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "filter-title",
                                  attrs: { span: 24, align: "left" }
                                },
                                [
                                  _vm._v(
                                    "\n                Affaires\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Secteur Activité  : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.affaire.secteurAct,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.affaire,
                                          "secteurAct",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.affaire.secteurAct"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("list-secteur-act-multiple", {
                                    key:
                                      "secteur_activite_" +
                                      _vm.filters[0].value.join("_"),
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.filters[0].value },
                                    on: {
                                      "update:data": function($event) {
                                        return _vm.$set(
                                          _vm.filters[0],
                                          "value",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Secteur Géo : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.affaire.secteurGeo,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.affaire,
                                          "secteurGeo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.affaire.secteurGeo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("list-secteur-geo-multiple", {
                                    key:
                                      "secteur_geo_" +
                                      _vm.filters[3].value.join("_"),
                                    staticStyle: { width: "100%" },
                                    attrs: { data: _vm.filters[3].value },
                                    on: {
                                      "update:data": function($event) {
                                        return _vm.$set(
                                          _vm.filters[3],
                                          "value",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Client : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: _vm.columnSelected.affaire.client,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.affaire,
                                          "client",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.affaire.client"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("list-client", {
                                    key: "id_client_" + _vm.filters[1].value,
                                    staticStyle: { width: "100%" },
                                    attrs: { idClient: _vm.filters[1].value },
                                    on: {
                                      "update:idClient": function($event) {
                                        return _vm.$set(
                                          _vm.filters[1],
                                          "value",
                                          $event
                                        )
                                      },
                                      "update:id-client": function($event) {
                                        return _vm.$set(
                                          _vm.filters[1],
                                          "value",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Enseigne : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.affaire.enseigne,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.affaire,
                                          "enseigne",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.affaire.enseigne"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("list-enseigne", {
                                    key:
                                      "id_enseigne_" +
                                      _vm.filters[1].value +
                                      "_" +
                                      _vm.filters[2].value,
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      idClient: _vm.filters[1].value,
                                      idEnseigne: _vm.filters[2].value,
                                      disabled: !_vm.filters[1].value
                                    },
                                    on: {
                                      "update:idClient": function($event) {
                                        return _vm.$set(
                                          _vm.filters[1],
                                          "value",
                                          $event
                                        )
                                      },
                                      "update:id-client": function($event) {
                                        return _vm.$set(
                                          _vm.filters[1],
                                          "value",
                                          $event
                                        )
                                      },
                                      "update:idEnseigne": function($event) {
                                        return _vm.$set(
                                          _vm.filters[2],
                                          "value",
                                          $event
                                        )
                                      },
                                      "update:id-enseigne": function($event) {
                                        return _vm.$set(
                                          _vm.filters[2],
                                          "value",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                N° Affaire : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: _vm.columnSelected.affaire.numero,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.affaire,
                                          "numero",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.affaire.numero"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "remote-method":
                                          _vm.searchAffaireByNumero,
                                        loading: _vm.affaires.loading,
                                        clearable: "",
                                        filterable: "",
                                        placeholder: "Entrez un n° d'affaire",
                                        remote: "",
                                        "reserve-keyword": "",
                                        size: "mini"
                                      },
                                      model: {
                                        value: _vm.filters[4].value,
                                        callback: function($$v) {
                                          _vm.$set(_vm.filters[4], "value", $$v)
                                        },
                                        expression: "filters[4].value"
                                      }
                                    },
                                    _vm._l(_vm.affaires.options, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Code analytique : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.affaire
                                          .codeAnalytique,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.affaire,
                                          "codeAnalytique",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.affaire.codeAnalytique"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[41].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[41], "value", $$v)
                                      },
                                      expression: "filters[41].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[42].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[42], "value", $$v)
                                      },
                                      expression: "filters[42].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Nom : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: _vm.columnSelected.affaire.nom,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.affaire,
                                          "nom",
                                          $$v
                                        )
                                      },
                                      expression: "columnSelected.affaire.nom"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "255",
                                      placeholder: "Nom de l'affaire",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[44].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[44], "value", $$v)
                                      },
                                      expression: "filters[44].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "filter-title",
                                  attrs: { span: 24, align: "left" }
                                },
                                [
                                  _vm._v(
                                    "\n                Commandes\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                N° de commande : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: _vm.columnSelected.commande.numero,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.commande,
                                          "numero",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.commande.numero"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                  de : \n                  "
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[5].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[5], "value", $$v)
                                      },
                                      expression: "filters[5].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                   à : \n                  "
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[6].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[6], "value", $$v)
                                      },
                                      expression: "filters[6].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Date de commande : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: _vm.columnSelected.commande.date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.commande,
                                          "date",
                                          $$v
                                        )
                                      },
                                      expression: "columnSelected.commande.date"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                du: \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[7].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[7], "value", $$v)
                                      },
                                      expression: "filters[7].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                au : \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[8].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[8], "value", $$v)
                                      },
                                      expression: "filters[8].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Nb factures / commande : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.commande.nbFacture,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.commande,
                                          "nbFacture",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.commande.nbFacture"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[9].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[9], "value", $$v)
                                      },
                                      expression: "filters[9].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[10].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[10], "value", $$v)
                                      },
                                      expression: "filters[10].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                %age avancement / commande : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.commande.avancement,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.commande,
                                          "avancement",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.commande.avancement"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de: \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[11].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[11], "value", $$v)
                                      },
                                      expression: "filters[11].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[12].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[12], "value", $$v)
                                      },
                                      expression: "filters[12].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Solde HT à facturer / commande : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.commande
                                          .soldeHtAFacturer,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.commande,
                                          "soldeHtAFacturer",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.commande.soldeHtAFacturer"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[13].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[13], "value", $$v)
                                      },
                                      expression: "filters[13].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[14].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[14], "value", $$v)
                                      },
                                      expression: "filters[14].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Emetteur : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.commande.emetteur,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.commande,
                                          "emetteur",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.commande.emetteur"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "255",
                                      placeholder: "Emetteur de la commande",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[40].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[40], "value", $$v)
                                      },
                                      expression: "filters[40].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Libellé de commande : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.commande.libelle,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.commande,
                                          "libelle",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.commande.libelle"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "255",
                                      placeholder: "Libellé commande",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[45].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[45], "value", $$v)
                                      },
                                      expression: "filters[45].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "filter-title",
                                  attrs: { span: 24, align: "left" }
                                },
                                [
                                  _vm._v(
                                    "\n                Factures\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Libellé de facture : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: _vm.columnSelected.facture.libelle,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "libelle",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.libelle"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "255",
                                      placeholder: "Libellé facture",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[43].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[43], "value", $$v)
                                      },
                                      expression: "filters[43].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                N° de facture : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: _vm.columnSelected.facture.numero,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "numero",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.numero"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[15].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[15], "value", $$v)
                                      },
                                      expression: "filters[15].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[16].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[16], "value", $$v)
                                      },
                                      expression: "filters[16].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Date de facture : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture.dateFacture,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "dateFacture",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.dateFacture"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                du: \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[17].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[17], "value", $$v)
                                      },
                                      expression: "filters[17].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                au : \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[18].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[18], "value", $$v)
                                      },
                                      expression: "filters[18].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                HT facturé : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture.montantHt,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "montantHt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.montantHt"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[19].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[19], "value", $$v)
                                      },
                                      expression: "filters[19].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[20].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[20], "value", $$v)
                                      },
                                      expression: "filters[20].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                TVA facturé : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture.montantTva,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "montantTva",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.montantTva"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[21].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[21], "value", $$v)
                                      },
                                      expression: "filters[21].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[22].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[22], "value", $$v)
                                      },
                                      expression: "filters[22].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                R.G. : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture
                                          .retenueGarantie,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "retenueGarantie",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.retenueGarantie"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[23].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[23], "value", $$v)
                                      },
                                      expression: "filters[23].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[24].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[24], "value", $$v)
                                      },
                                      expression: "filters[24].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                TTC avant P.D. : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture
                                          .mtAvantPaiementDirectTtc,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "mtAvantPaiementDirectTtc",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.mtAvantPaiementDirectTtc"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[25].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[25], "value", $$v)
                                      },
                                      expression: "filters[25].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[26].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[26], "value", $$v)
                                      },
                                      expression: "filters[26].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Paiement Direct : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture
                                          .mtPaiementDirectSt,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "mtPaiementDirectSt",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.mtPaiementDirectSt"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[27].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[27], "value", $$v)
                                      },
                                      expression: "filters[27].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[28].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[28], "value", $$v)
                                      },
                                      expression: "filters[28].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                TTC après P.D. : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture
                                          .mtAPercevoirTtc,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "mtAPercevoirTtc",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.mtAPercevoirTtc"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[29].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[29], "value", $$v)
                                      },
                                      expression: "filters[29].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[30].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[30], "value", $$v)
                                      },
                                      expression: "filters[30].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Date d'échéance : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture.dateEcheance,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "dateEcheance",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.dateEcheance"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                du: \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[31].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[31], "value", $$v)
                                      },
                                      expression: "filters[31].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                au : \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[32].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[32], "value", $$v)
                                      },
                                      expression: "filters[32].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Solde restant dû : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: _vm.columnSelected.facture.solde,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "solde",
                                          $$v
                                        )
                                      },
                                      expression: "columnSelected.facture.solde"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[37].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[37], "value", $$v)
                                      },
                                      expression: "filters[37].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[38].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[38], "value", $$v)
                                      },
                                      expression: "filters[38].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Commentaires : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture.commentaire,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "commentaire",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.commentaire"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "255",
                                      placeholder: "Contenu du commentaire",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[39].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[39], "value", $$v)
                                      },
                                      expression: "filters[39].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Montant co-traitance : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.facture.mtDiversTtc,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.facture,
                                          "mtDiversTtc",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.facture.mtDiversTtc"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[46].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[46], "value", $$v)
                                      },
                                      expression: "filters[46].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[47].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[47], "value", $$v)
                                      },
                                      expression: "filters[47].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "filter-title",
                                  attrs: { span: 24, align: "left" }
                                },
                                [
                                  _vm._v(
                                    "\n                Règlements\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Date de règlement : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value: _vm.columnSelected.reglement.date,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.reglement,
                                          "date",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.reglement.date"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                du: \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[35].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[35], "value", $$v)
                                      },
                                      expression: "filters[35].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                au : \n                "
                                  ),
                                  _c("el-date-picker", {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/####",
                                        expression: "'##/##/####'"
                                      }
                                    ],
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      format: "dd/MM/yyyy",
                                      clearable: "",
                                      placeholder: "",
                                      size: "mini",
                                      type: "date",
                                      "value-format": "yyyy-MM-dd"
                                    },
                                    model: {
                                      value: _vm.filters[36].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[36], "value", $$v)
                                      },
                                      expression: "filters[36].value"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.label,
                                    offset: 1,
                                    align: "right"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                Montant : \n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    span: _vm.rowSpan.selection,
                                    align: "center"
                                  }
                                },
                                [
                                  _c("el-checkbox", {
                                    model: {
                                      value:
                                        _vm.columnSelected.reglement.montant,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.columnSelected.reglement,
                                          "montant",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "columnSelected.reglement.montant"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                { attrs: { span: _vm.rowSpan.field } },
                                [
                                  _vm._v(
                                    "\n                de : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[33].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[33], "value", $$v)
                                      },
                                      expression: "filters[33].value"
                                    }
                                  }),
                                  _vm._v(
                                    "\n                 à : \n                "
                                  ),
                                  _c("el-input", {
                                    directives: [
                                      {
                                        name: "numberonly",
                                        rawName: "v-numberonly"
                                      }
                                    ],
                                    staticClass: "numeric",
                                    staticStyle: { width: "125px" },
                                    attrs: {
                                      autocomplete: "no",
                                      clearable: "",
                                      maxlength: "45",
                                      size: "mini"
                                    },
                                    model: {
                                      value: _vm.filters[34].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters[34], "value", $$v)
                                      },
                                      expression: "filters[34].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ]
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-row",
                { attrs: { id: "anchor-table-site" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, align: "right" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-d-arrow-right",
                            type: "primary",
                            size: "mini",
                            loading: _vm.btnExportLoading.excel,
                            disabled: _vm.total === 0
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleExport("excel")
                            }
                          }
                        },
                        [_vm._v("\n            Export Excel\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "StickyTable",
            {
              attrs: {
                classIdent: ".synthese-list",
                tableClass: ".dataTable",
                top: 50,
                closeWidth: 174,
                openWidth: 330
              }
            },
            [
              _vm.columnGroupSelected.one
                ? _c(
                    "data-tables",
                    {
                      ref: "dataTable",
                      staticClass: "dataTable",
                      attrs: {
                        data: _vm.dataTable,
                        "current-page": _vm.currentPage,
                        "page-size": _vm.pageSize,
                        total: _vm.total,
                        "pagination-props": { pageSizes: [100, 250, 500] },
                        filters: _vm.filters,
                        "sort-method": _vm.sortMethod,
                        "table-props": {
                          fit: true,
                          size: "mini",
                          defaultSort: {
                            prop: "date_facture",
                            order: "descending"
                          }
                        },
                        "default-expand-all": "",
                        layout: "table, pagination"
                      }
                    },
                    [
                      _vm.columnSelected.affaire.numero
                        ? _c("el-table-column", {
                            key: _vm.columns["affaire.num_affaire"].key,
                            attrs: {
                              sortable: "custom",
                              label: _vm.columns["affaire.num_affaire"].label,
                              prop: _vm.columns["affaire.num_affaire"].prop,
                              width: _vm.columns["affaire.num_affaire"].width,
                              fixed: "left",
                              "class-name": "left"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to:
                                              "/affaire/" + scope.row.affaire.id
                                          }
                                        },
                                        [
                                          _c(
                                            "el-link",
                                            { attrs: { type: "primary" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.affaire.num_affaire
                                                )
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4084372459
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columnSelected.affaire.secteurAct
                        ? _c("el-table-column", {
                            key: _vm.columns["affaire.secteur_act_code"].key,
                            attrs: {
                              sortable: "custom",
                              label:
                                _vm.columns["affaire.secteur_act_code"].label,
                              prop:
                                _vm.columns["affaire.secteur_act_code"].prop,
                              width:
                                _vm.columns["affaire.secteur_act_code"].width,
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            scope.row.affaire.secteur_act.code
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3910206980
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columnGroupSelected.commande
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "Commande" } },
                            [
                              _vm.columnSelected.commande.date
                                ? _c("el-table-column", {
                                    key: _vm.columns["commande.date"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label: _vm.columns["commande.date"].label,
                                      prop: _vm.columns["commande.date"].prop,
                                      width: _vm.columns["commande.date"].width,
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toDate")(
                                                      scope.row.commande.date
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      731421011
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.commande.numero
                                ? _c("el-table-column", {
                                    key: _vm.columns["commande.numero"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["commande.numero"].label,
                                      prop: _vm.columns["commande.numero"].prop,
                                      width:
                                        _vm.columns["commande.numero"].width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.commande.numero
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      178480319
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-table-column", {
                                key: _vm.columns["commande.montant"].key,
                                attrs: {
                                  sortable: "custom",
                                  label: _vm.columns["commande.montant"].label,
                                  prop: _vm.columns["commande.montant"].prop,
                                  width: _vm.columns["commande.montant"].width,
                                  align: "right"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm._f("toCurrency")(
                                                  scope.row.commande.montant
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2414628759
                                )
                              }),
                              _vm._v(" "),
                              _vm.columnSelected.commande.nbFacture
                                ? _c("el-table-column", {
                                    key: _vm.columns["commande.nb_facture"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["commande.nb_facture"]
                                          .label,
                                      prop:
                                        _vm.columns["commande.nb_facture"].prop,
                                      width:
                                        _vm.columns["commande.nb_facture"]
                                          .width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.commande
                                                      .nb_facture
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      335530384
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.commande.avancement
                                ? _c("el-table-column", {
                                    key: _vm.columns["commande.avancement"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["commande.avancement"]
                                          .label,
                                      prop:
                                        _vm.columns["commande.avancement"].prop,
                                      width:
                                        _vm.columns["commande.avancement"]
                                          .width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toPercentage")(
                                                      scope.row.commande
                                                        .avancement
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4018363335
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.commande.soldeHtAFacturer
                                ? _c("el-table-column", {
                                    key:
                                      _vm.columns[
                                        "commande.solde_ht_a_facturer"
                                      ].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns[
                                          "commande.solde_ht_a_facturer"
                                        ].label,
                                      prop:
                                        _vm.columns[
                                          "commande.solde_ht_a_facturer"
                                        ].prop,
                                      width:
                                        _vm.columns[
                                          "commande.solde_ht_a_facturer"
                                        ].width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      scope.row.commande
                                                        .solde_ht_a_facturer
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3561373383
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.commande.emetteur
                                ? _c("el-table-column", {
                                    key: _vm.columns["commande.emetteur"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["commande.emetteur"].label,
                                      prop:
                                        _vm.columns["commande.emetteur"].prop,
                                      width:
                                        _vm.columns["commande.emetteur"].width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.commande.emetteur
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1043523614
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.commande.libelle
                                ? _c("el-table-column", {
                                    key: _vm.columns["commande.libelle"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["commande.libelle"].label,
                                      prop:
                                        _vm.columns["commande.libelle"].prop,
                                      width:
                                        _vm.columns["commande.libelle"].width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.commande.libelle
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1072337046
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columnGroupSelected.facture
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "Facture" } },
                            [
                              _vm.columnSelected.facture.dateFacture
                                ? _c("el-table-column", {
                                    key:
                                      _vm.columns["facture.date_facture"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["facture.date_facture"]
                                          .label,
                                      prop:
                                        _vm.columns["facture.date_facture"]
                                          .prop,
                                      width:
                                        _vm.columns["facture.date_facture"]
                                          .width,
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toDate")(
                                                      scope.row.facture
                                                        .date_facture
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2174412142
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.numero
                                ? _c("el-table-column", {
                                    key: _vm.columns["facture.numero"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["facture.numero"].label,
                                      prop: _vm.columns["facture.numero"].prop,
                                      width: _vm.columns["facture.numero"].width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.facture.numero
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3421263919
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.libelle
                                ? _c("el-table-column", {
                                    key: _vm.columns["facture.libelle"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["facture.libelle"].label,
                                      prop: _vm.columns["facture.libelle"].prop,
                                      width:
                                        _vm.columns["facture.libelle"].width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.facture.libelle
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2785689190
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.montantHt
                                ? _c("el-table-column", {
                                    key: _vm.columns["facture.montant_ht"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["facture.montant_ht"].label,
                                      prop:
                                        _vm.columns["facture.montant_ht"].prop,
                                      width:
                                        _vm.columns["facture.montant_ht"].width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      scope.row.facture
                                                        .montant_ht
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      929062372
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.montantTva
                                ? _c("el-table-column", {
                                    key: _vm.columns["facture.montant_tva"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["facture.montant_tva"]
                                          .label,
                                      prop:
                                        _vm.columns["facture.montant_tva"].prop,
                                      width:
                                        _vm.columns["facture.montant_tva"]
                                          .width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      scope.row.facture
                                                        .montant_tva
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      698839387
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.retenueGarantie
                                ? _c("el-table-column", {
                                    key:
                                      _vm.columns["facture.retenue_garantie"]
                                        .key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["facture.retenue_garantie"]
                                          .label,
                                      prop:
                                        _vm.columns["facture.retenue_garantie"]
                                          .prop,
                                      width:
                                        _vm.columns["facture.retenue_garantie"]
                                          .width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      scope.row.facture
                                                        .retenue_garantie
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      157916992
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture
                                .mtAvantPaiementDirectTtc
                                ? _c("el-table-column", {
                                    key:
                                      _vm.columns[
                                        "facture.mt_avant_paiement_direct_ttc"
                                      ].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns[
                                          "facture.mt_avant_paiement_direct_ttc"
                                        ].label,
                                      prop:
                                        _vm.columns[
                                          "facture.mt_avant_paiement_direct_ttc"
                                        ].prop,
                                      width:
                                        _vm.columns[
                                          "facture.mt_avant_paiement_direct_ttc"
                                        ].width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      scope.row.facture
                                                        .mt_avant_paiement_direct_ttc
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      893776
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.mtPaiementDirectSt
                                ? _c("el-table-column", {
                                    key:
                                      _vm.columns[
                                        "facture.mt_paiement_direct_st"
                                      ].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns[
                                          "facture.mt_paiement_direct_st"
                                        ].label,
                                      prop:
                                        _vm.columns[
                                          "facture.mt_paiement_direct_st"
                                        ].prop,
                                      width:
                                        _vm.columns[
                                          "facture.mt_paiement_direct_st"
                                        ].width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      scope.row.facture
                                                        .mt_paiement_direct_st
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2843021191
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.mtAPercevoirTtc
                                ? _c("el-table-column", {
                                    key:
                                      _vm.columns["facture.mt_a_percevoir_ttc"]
                                        .key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns[
                                          "facture.mt_a_percevoir_ttc"
                                        ].label,
                                      prop:
                                        _vm.columns[
                                          "facture.mt_a_percevoir_ttc"
                                        ].prop,
                                      width:
                                        _vm.columns[
                                          "facture.mt_a_percevoir_ttc"
                                        ].width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      scope.row.facture
                                                        .mt_a_percevoir_ttc
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2734949539
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.solde
                                ? _c("el-table-column", {
                                    key: _vm.columns["facture.solde"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label: _vm.columns["facture.solde"].label,
                                      prop: _vm.columns["facture.solde"].prop,
                                      width: _vm.columns["facture.solde"].width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      scope.row.facture.solde
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1697821589
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.mtDiversTtc
                                ? _c("el-table-column", {
                                    key: _vm.columns["facture.divers_ttc"].key,
                                    attrs: {
                                      align: "right",
                                      sortable: "custom",
                                      label:
                                        _vm.columns["facture.divers_ttc"].label,
                                      prop:
                                        _vm.columns["facture.divers_ttc"].prop,
                                      width:
                                        _vm.columns["facture.divers_ttc"].width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toCurrency")(
                                                      scope.row
                                                        .facture_divers_ttc
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3137380406
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.facture.dateEcheance
                                ? _c("el-table-column", {
                                    key:
                                      _vm.columns["facture.date_echeance"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["facture.date_echeance"]
                                          .label,
                                      prop:
                                        _vm.columns["facture.date_echeance"]
                                          .prop,
                                      width:
                                        _vm.columns["facture.date_echeance"]
                                          .width,
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm._f("toDate")(
                                                      scope.row.facture
                                                        .date_echeance
                                                    )
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1108885598
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columnGroupSelected.reglement
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "Règlement" } },
                            [
                              _vm.columnSelected.reglement.date
                                ? _c("el-table-column", {
                                    key: _vm.columns["reglement.date"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["reglement.date"].label,
                                      prop: _vm.columns["reglement.date"].prop,
                                      width:
                                        _vm.columns["reglement.date"].width,
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.reglements.length === 0
                                                ? _c("div", [
                                                    _vm._v("aucun règlement")
                                                  ])
                                                : scope.row.reglements
                                                    .length === 1
                                                ? _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("toDate")(
                                                          scope.row
                                                            .reglements[0].date
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.showNbReglement(
                                                          scope.row.reglements
                                                        )
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1229057512
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.reglement.montant
                                ? _c("el-table-column", {
                                    key: _vm.columns["reglement.montant"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["reglement.montant"].label,
                                      prop:
                                        _vm.columns["reglement.montant"].prop,
                                      width:
                                        _vm.columns["reglement.montant"].width,
                                      align: "right"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.reglements.length === 0
                                                ? _c("div", [_vm._v("-")])
                                                : scope.row.reglements
                                                    .length === 1
                                                ? _c("div", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("toCurrency")(
                                                          scope.row
                                                            .reglements[0]
                                                            .montant
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1763049316
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("el-table-column", {
                                key: _vm.columns["reglement.mode"].key,
                                attrs: {
                                  sortable: "custom",
                                  label: _vm.columns["reglement.mode"].label,
                                  prop: _vm.columns["reglement.mode"].prop,
                                  width: _vm.columns["reglement.mode"].width,
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          scope.row.reglements.length === 0
                                            ? _c("div", [_vm._v("-")])
                                            : scope.row.reglements.length === 1
                                            ? _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f(
                                                      "getLabelModeReglement"
                                                    )(
                                                      scope.row.reglements[0]
                                                        .mode
                                                    )
                                                  )
                                                )
                                              ])
                                            : _c("div", [
                                                _vm._v(
                                                  "multiple (" +
                                                    _vm._s(
                                                      scope.row.reglements
                                                        .length
                                                    ) +
                                                    ")"
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3474927497
                                )
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columnSelected.facture.commentaire
                        ? _c("el-table-column", {
                            key: _vm.columns["facture.commentaire"].key,
                            attrs: {
                              sortable: "custom",
                              label: _vm.columns["facture.commentaire"].label,
                              prop: _vm.columns["facture.commentaire"].prop,
                              width: _vm.columns["facture.commentaire"].width
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("div", [
                                        scope.row.facture.commentaire
                                          ? _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "can",
                                                    rawName:
                                                      "v-can:facturation",
                                                    value: "suivre",
                                                    expression: "'suivre'",
                                                    arg: "facturation"
                                                  }
                                                ]
                                              },
                                              [
                                                _c("el-button", {
                                                  attrs: {
                                                    circle: "",
                                                    icon: "el-icon-info",
                                                    size: "mini",
                                                    type: "info"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.handleCommand({
                                                        action: "commentaire",
                                                        row: Object.assign(
                                                          {},
                                                          scope.row
                                                        )
                                                      })
                                                    }
                                                  }
                                                }),
                                                _vm._v(
                                                  "\n                   \n                  "
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              scope.row.facture.commentaire
                                            ) +
                                            "\n            "
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              230368333
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.columnGroupSelected.affaire
                        ? _c(
                            "el-table-column",
                            { attrs: { label: "Affaire" } },
                            [
                              _vm.columnSelected.affaire.secteurGeo
                                ? _c("el-table-column", {
                                    key:
                                      _vm.columns["affaire.secteur_geo_libelle"]
                                        .key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns[
                                          "affaire.secteur_geo_libelle"
                                        ].label,
                                      prop:
                                        _vm.columns[
                                          "affaire.secteur_geo_libelle"
                                        ].prop,
                                      width:
                                        _vm.columns[
                                          "affaire.secteur_geo_libelle"
                                        ].width,
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.affaire
                                                      .secteur_geo.libelle
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      537334709
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.affaire.codeAnalytique
                                ? _c("el-table-column", {
                                    key:
                                      _vm.columns["affaire.code_analytique"]
                                        .key,
                                    attrs: {
                                      sortable: "custom",
                                      label:
                                        _vm.columns["affaire.code_analytique"]
                                          .label,
                                      prop:
                                        _vm.columns["affaire.code_analytique"]
                                          .prop,
                                      width:
                                        _vm.columns["affaire.code_analytique"]
                                          .width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.affaire
                                                      .code_analytique
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2765297784
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.affaire.nom
                                ? _c("el-table-column", {
                                    key: _vm.columns["affaire.nom"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label: _vm.columns["affaire.nom"].label,
                                      prop: _vm.columns["affaire.nom"].prop,
                                      width: _vm.columns["affaire.nom"].width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.affaire.nom
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      843449441
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.affaire.enseigne
                                ? _c("el-table-column", {
                                    key: _vm.columns["enseigne"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label: _vm.columns["enseigne"].label,
                                      prop: _vm.columns["enseigne"].prop,
                                      width: _vm.columns["enseigne"].width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.affaire.enseigne
                                                      .libelle
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      978592252
                                    )
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.columnSelected.affaire.client
                                ? _c("el-table-column", {
                                    key: _vm.columns["client"].key,
                                    attrs: {
                                      sortable: "custom",
                                      label: _vm.columns["client"].label,
                                      prop: _vm.columns["client"].prop,
                                      width: _vm.columns["client"].width
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    scope.row.affaire.client
                                                      .libelle
                                                  ) +
                                                  "\n            "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      925091229
                                    )
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c("div", [
                    _vm._v(
                      "Veuillez sélectionner au moins une colonne à afficher dans les critères de filtre ci-dessus."
                    )
                  ])
            ],
            1
          ),
          _vm._v(" "),
          _c("Pagination", {
            key: "Pagination_" + _vm.total,
            staticStyle: { "margin-bottom": "10px" },
            attrs: {
              pageIdent: _vm.pageIdent,
              "current-page": _vm.currentPage,
              "page-size": _vm.pageSize,
              total: _vm.total
            },
            on: {
              "update:currentPage": function($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function($event) {
                _vm.currentPage = $event
              },
              "update:pageSize": function($event) {
                _vm.pageSize = $event
              },
              "update:page-size": function($event) {
                _vm.pageSize = $event
              }
            }
          }),
          _vm._v(" "),
          _c("ScrollBar")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Commentaire pour la facture n°" + _vm.rowComment.numero,
            visible: _vm.dialogFactureCommentaireVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFactureCommentaireVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [_c("el-col", [_c("strong", [_vm._v("Commentaire")])])],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", [
                _c("span", {
                  domProps: { innerHTML: _vm._s("<br>" + _vm.rowCommentHtml) }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFactureCommentaireVisible = false
                    }
                  }
                },
                [_vm._v("\n          Fermer\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }