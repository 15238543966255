var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-pagination", {
    key: _vm.refresh,
    staticClass: "custom-pagination",
    attrs: {
      "current-page": _vm.localCurrentPage,
      "page-size": _vm.localPageSize,
      total: _vm.total,
      "page-sizes": [100, 250, 500],
      layout: "prev, pager, next, jumper, sizes, total"
    },
    on: {
      "size-change": _vm.handleSizeChange,
      "current-change": _vm.handleCurrentChange
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }