var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Liste des commandes")]),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { slot: "tool", gutter: 10 }, slot: "tool" },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _vm.$store.getters.affaire.affaire.status !==
              _vm.CONST.AFFAIRE.STATUS.CLOSED.value &&
            _vm.$store.getters.affaire.affaire.status !==
              _vm.CONST.AFFAIRE.STATUS.ARCHIVED.value
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          icon: "el-icon-circle-plus-outline",
                          size: "mini",
                          type: "primary"
                        },
                        on: { click: _vm.handleNewCommande }
                      },
                      [_vm._v("\n          Nouvelle commande\n        ")]
                    ),
                    _vm._v(" "),
                    _vm.multipleSelection.length > 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "can",
                                rawName: "v-can:facturation",
                                value: "administrer",
                                expression: "'administrer'",
                                arg: "facturation"
                              }
                            ],
                            attrs: {
                              icon: "el-icon-delete",
                              size: "mini",
                              type: "danger"
                            },
                            on: { click: _vm.handleDeleteSelection }
                          },
                          [
                            _vm._v(
                              "\n          Supprimer la sélection\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { "white-space": "nowrap" },
              attrs: { span: 12, align: "right" }
            },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "250px" },
                  attrs: {
                    clearable: "",
                    placeholder: "Rechercher",
                    size: "mini"
                  },
                  model: {
                    value: _vm.filters[0].value,
                    callback: function($$v) {
                      _vm.$set(_vm.filters[0], "value", $$v)
                    },
                    expression: "filters[0].value"
                  }
                },
                [_vm._v("\n        >\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "data-tables",
        {
          directives: [
            {
              name: "fixed-header",
              rawName: "v-fixed-header",
              value: { startFixed: 150 },
              expression: "{ startFixed: 150 }"
            }
          ],
          ref: "dataTable",
          attrs: {
            data: _vm.data,
            filters: _vm.filters,
            "table-props": {
              showSummary: true,
              summaryMethod: _vm.getSummaries,
              defaultSort: { prop: "date_commande", order: "descending" }
            },
            total: _vm.total,
            layout: "tool, table"
          },
          on: {
            "row-click": function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleCurrentChange($event)
            },
            "selection-change": _vm.handleSelectionChange
          }
        },
        [
          _c("el-table-column", {
            directives: [
              {
                name: "can",
                rawName: "v-can:facturation",
                value: "administrer",
                expression: "'administrer'",
                arg: "facturation"
              }
            ],
            attrs: { type: "selection", width: "45" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            directives: [
              {
                name: "can",
                rawName: "v-can:facturation",
                value: "administrer",
                expression: "'administrer'",
                arg: "facturation"
              }
            ],
            attrs: { width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-button", {
                      attrs: { icon: "el-icon-delete", type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleDeleteCommande(
                            scope.$index,
                            scope.row
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("el-button", {
                      attrs: { icon: "el-icon-edit", type: "text" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleEditCommande(scope.$index, scope.row)
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["numero"].key,
            attrs: {
              label: _vm.columns["numero"].label,
              prop: "numero",
              width: _vm.columns["numero"].width,
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row["numero"]))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["date_commande"].key,
            attrs: {
              label: _vm.columns["date_commande"].label,
              prop: "date_commande",
              "filter-method": _vm.handleFilter,
              filters: _vm.filterDateCommande,
              width: _vm.columns["date_commande"].width,
              sortable: "custom",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _c("i", { staticClass: "el-icon-date" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("toDate")(scope.row["date_commande"]))
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["montant"].key,
            attrs: {
              label: _vm.columns["montant"].label,
              prop: "montant",
              width: _vm.columns["montant"].width,
              align: "right",
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(_vm._s(_vm._f("toCurrency")(scope.row["montant"])))
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["nb_facture"].key,
            attrs: {
              label: _vm.columns["nb_facture"].label,
              prop: "nb_facture",
              width: _vm.columns["nb_facture"].width,
              align: "right",
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row["nb_facture"]))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["montant_facture"].key,
            attrs: {
              label: _vm.columns["montant_facture"].label,
              prop: "montant_facture",
              width: _vm.columns["montant_facture"].width,
              align: "right",
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(scope.row["montant_facture"])
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["pourcentage_avancement"].key,
            attrs: {
              label: _vm.columns["pourcentage_avancement"].label,
              prop: "pourcentage_avancement",
              width: _vm.columns["pourcentage_avancement"].width,
              align: "right",
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("toPercentage")(
                            scope.row["pourcentage_avancement"]
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["solde_ht_a_facturer"].key,
            attrs: {
              label: _vm.columns["solde_ht_a_facturer"].label,
              prop: "solde_ht_a_facturer",
              width: _vm.columns["solde_ht_a_facturer"].width,
              align: "right",
              sortable: "custom"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(scope.row["solde_ht_a_facturer"])
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["emetteur"].key,
            attrs: {
              label: _vm.columns["emetteur"].label,
              width: _vm.columns["emetteur"].width,
              "column-key": "emetteur",
              prop: "emetteur",
              sortable: ""
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row["emetteur"]))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["libelle"].key,
            attrs: {
              sortable: "custom",
              label: _vm.columns["libelle"].label,
              prop: _vm.columns["libelle"].prop,
              width: _vm.columns["libelle"].width
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.libelle))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            key: _vm.columns["commentaire"].key,
            attrs: {
              sortable: "custom",
              label: _vm.columns["commentaire"].label,
              prop: _vm.columns["commentaire"].prop,
              width: _vm.columns["commentaire"].width
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("div", [_vm._v(_vm._s(scope.row.commentaire))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.$route.name === "ViewAffaire"
            ? _c("el-table-column", {
                attrs: { align: "right", width: "200" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { icon: "el-icon-files", type: "text" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handleManageFacture(
                                    scope.$index,
                                    scope.row
                                  )
                                }
                              }
                            },
                            [_vm._v("\n          Gérer les factures\n        ")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2190617666
                )
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: { title: "Détail", visible: _vm.dialogDetailVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogDetailVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("strong", [_vm._v("Numéro")])
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("strong", [_vm._v(_vm._s(_vm.row.numero))])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [_vm._v("Date")]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("i", { staticClass: "el-icon-date" }),
                _vm._v(" " + _vm._s(_vm._f("toDate")(_vm.row.date_commande)))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [_vm._v("Montant")]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _vm._v(_vm._s(_vm._f("toCurrency")(_vm.row.montant)))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [_vm._v("Nb facture")]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _vm._v(_vm._s(_vm.row.nb_facture))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v("Montant HT facture")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _vm._v(_vm._s(_vm.row.montant_facture))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [_vm._v("%age avancement")]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _vm._v(
                  _vm._s(_vm._f("toPercentage")(_vm.row.pourcentage_avancement))
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v("Solde HT à facturer")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _vm._v(_vm._s(_vm.row.solde_ht_a_facturer))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [_vm._v("Émetteur")]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _vm._v(_vm._s(_vm.row.emetteur))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v("Libellé commande")
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _vm._v(_vm._s(_vm.row.libelle))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [_vm._v("Commentaires")]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 16 } }, [
                _vm._v(_vm._s(_vm.row.commentaire))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }