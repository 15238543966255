"use strict";

var _interopRequireWildcard = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.array.find");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

var _utils = require("@/helpers/utils");

var _moment = _interopRequireDefault(require("moment"));

var CONST = _interopRequireWildcard(require("@/helpers/constantes"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'CommandeList',
  data: function data() {
    return {
      CONST: CONST,
      row: [],
      dialogDetailVisible: false,
      multipleSelection: [],
      columns: {
        'numero': {
          key: 0,
          prop: 'numero',
          label: 'Numéro',
          width: 200
        },
        'date_commande': {
          key: 1,
          prop: 'date_commande',
          label: 'Date',
          width: 110
        },
        'montant': {
          key: 2,
          prop: 'montant',
          label: 'Montant',
          width: 120
        },
        'nb_facture': {
          key: 3,
          prop: 'nb_facture',
          label: 'Nb factures',
          width: 75
        },
        'montant_facture': {
          key: 4,
          prop: 'montant_facture',
          label: 'Montant HT factures',
          width: 120
        },
        'pourcentage_avancement': {
          key: 5,
          prop: 'pourcentage_avancement',
          label: '%age avancement',
          width: 100
        },
        'solde_ht_a_facturer': {
          key: 6,
          prop: 'solde_ht_a_facturer',
          label: 'Solde Ht à facturer',
          width: 120
        },
        'emetteur': {
          key: 7,
          prop: 'emetteur',
          label: 'Émetteur',
          width: 350
        },
        'libelle': {
          key: 9,
          prop: 'libelle',
          label: 'Libellé commande',
          width: 300
        },
        'commentaire': {
          key: 8,
          prop: 'commentaire',
          label: 'Commentaire',
          width: 350
        }
      },
      filters: [{
        value: '',
        prop: ['numero', 'date_commande', 'montant', 'nb_facture', 'montant_facture', 'solde_ht_a_facturer', 'libelle', 'emetteur'],
        filterFn: function filterFn(row, filter) {
          return Object.keys(row).some(function (prop) {
            switch (prop) {
              case 'date_commande':
                if (!row[prop] || typeof row[prop] === 'undefined') {
                  return false;
                }

                return (0, _moment.default)(String(row[prop])).format('DD/MM/YYYY').indexOf(filter.value) > -1;

              case 'montant':
              case 'montant_facture':
              case 'solde_ht_a_facturer':
                return String((0, _utils.toCurrencyFilter)(row[prop])).toLowerCase().indexOf(filter.value.toLowerCase()) > -1;

              default:
                return String(row[prop]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
            }
          });
        }
      }],
      filterDateCommande: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)('facturation', ['getCommandeListForCurrentAffaire', // 'getFactureListByCommande',
  // 'getReglementListByFactureSelection',
  'getNbFactureByCommandeSelection']), {
    data: function data() {
      this.$loader(true);
      var data = this.getCommandeListForCurrentAffaire; // ---
      // Initialisation des valeurs des filtres de colonnes

      var filterDateCommande = [];
      data.forEach(function (row) {
        // Initialisation des valeurs du filtre pour la colonne "date de commande"
        if (!filterDateCommande.find(function (x) {
          return x.value === row.date_commande;
        })) {
          if (row.date_commande && typeof row.date_commande !== 'undefined') {
            var item = {
              text: row.date_commande,
              value: row.date_commande
            };
            item.text = (0, _moment.default)(String(row.date_commande)).format('DD/MM/YYYY');
            filterDateCommande.push(item);
          }
        }
      });
      this.setFilter('date_commande', filterDateCommande);
      this.$loader(false);
      return data;
    },
    total: function total() {
      return this.data.length;
    }
  }),
  // 9. Évènements
  // --
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('facturation', {
    initFacturation: 'init'
  }), {}, (0, _vuex.mapActions)('facturation', ['deleteCommandeAndLinkedData', 'bulkDeleteCommandeAndLinkedData']), {
    // permet de gérer indépendamment la largeur de chaque colonne en fonction de leur index
    colWidth: function colWidth(index) {
      if (this.columns[index] === undefined) {
        return;
      }

      return this.columns[index].width;
    },
    getSummaries: function getSummaries(param) {
      var columns = param.columns,
          data = param.data;
      var tempSums = [];
      var sums = [];
      columns.forEach(function (column, index) {
        if (column.property === 'numero') {
          sums[index] = 'Total';
          return;
        }

        if (!['montant', 'nb_facture', 'montant_facture', 'pourcentage_avancement', 'solde_ht_a_facturer'].includes(column.property)) {
          sums[index] = '';
          return;
        } // Pourcentage total d'avancement


        if (column.property === 'pourcentage_avancement') {
          sums[index] = sums[4] ? (0, _utils.toPercentage)(tempSums[6] / tempSums[4] * 100) : '';
          return;
        }

        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        sums[index] = (0, _utils.getTotal)(values); // Formattage

        if (['montant', 'montant_facture', 'solde_ht_a_facturer'].includes(column.property)) {
          tempSums[index] = sums[index];
          sums[index] = (0, _utils.toCurrency)(sums[index]);
        }
      });
      return sums;
    },
    setFilter: function setFilter(prop, filter) {
      switch (prop) {
        case 'date_commande':
          this.filterDateCommande = filter;
          break;
      }
    },
    handleFilter: function handleFilter(value, row, column) {
      var property = column['property'];
      return row[property] === value;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.row = val;
      this.dialogDetailVisible = true;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleNewCommande: function handleNewCommande() {
      _eventBus.EventBus.$emit('COMMANDE_NEW');
    },
    handleEditCommande: function handleEditCommande(index, row) {
      _eventBus.EventBus.$emit('COMMANDE_EDIT', row);
    },
    handleManageFacture: function handleManageFacture(index, row) {
      _eventBus.EventBus.$emit('TAB_MENU', 'factures_by_commande', row);
    },
    handleDeleteCommande: function handleDeleteCommande(index, row) {
      var _this = this;

      var msg = "Voulez-vous supprimer la commande ".concat(row.numero, " ?"); // Si la commande est liée à au moins une facture

      if (row.nb_facture > 0) {
        msg += "\n          <strong>Attention cette commande est li\xE9e \xE0 ".concat(row.nb_facture, " facture(s).</strong><br>\n          La suppression de cette commande entrainera la suppression de toutes les factures et r\xE9glements li\xE9s.\n        ");
        this.$alert("\n          <strong>Cette commande est li\xE9e \xE0 ".concat(row.nb_facture, " facture(s).</strong><br>\n          La suppression de cette commande n'est pas possible tant que des factures y sont li\xE9es.\n        "), 'Suppression de la commande impossible', {
          type: 'warning',
          confirmButtonText: 'Fermer',
          callback: function callback(action) {},
          dangerouslyUseHTMLString: true
        });
        return;
      } // Demande de confirmation de suppression


      this.$confirm(msg, 'Confirmer la suppression ?', {
        type: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        dangerouslyUseHTMLString: true
      }).then(function (_) {
        _this.$loader(true); // Suppression de la commande et des factures et réglements liés


        _this.deleteCommandeAndLinkedData(row).then(function () {
          _this.$message('Suppression effectuée avec succès');
        }).catch(function (error) {
          _this.$message(error);
        }).then(function (_) {
          _this.$loader(false);
        });
      }).catch(function (error) {
        if (error !== 'cancel') {
          console.log(error);

          _this.$message(error);
        }
      });
    },
    handleDeleteSelection: function handleDeleteSelection() {
      var _this2 = this;

      var selection = this.multipleSelection.map(function (u) {
        return u.id;
      }).join(','); // Factures liées à la sélection de commandes

      var factureNb = this.getNbFactureByCommandeSelection(selection);
      var msg = 'Voulez-vous supprimer ' + (this.multipleSelection.length > 1 ? 'les lignes sélectionnées' : 'la ligne sélectionnée') + ' ? '; // Si la sélection de commandes est liée à au moins une facture

      if (factureNb > 0) {
        msg += "\n          <strong>Attention cette s\xE9lection de commandes est li\xE9e \xE0 ".concat(factureNb, " facture(s).</strong><br>\n          La suppression de cette s\xE9lection de commandes entrainera la suppression de toutes les factures et r\xE9glements li\xE9s.\n        ");
        this.$alert("\n          <strong>Cette s\xE9lection de commandes est li\xE9e \xE0 ".concat(factureNb, " facture(s).</strong><br>\n          La suppression de cette s\xE9lection de commandes n'est pas possible tant que des factures y sont li\xE9es.\n        "), 'Suppression de la sélection impossible', {
          type: 'warning',
          confirmButtonText: 'Fermer',
          callback: function callback(action) {},
          dangerouslyUseHTMLString: true
        });
        return;
      } // Demande de confirmation de suppression


      this.$confirm(msg, 'Confirmer la suppression', {
        type: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        dangerouslyUseHTMLString: true
      }).then(function () {
        _this2.$loader(true); // Suppression de la sélection de commandes et des factures et réglements liés


        _this2.bulkDeleteCommandeAndLinkedData(selection).then(function () {
          _this2.$message('Suppression effectuée avec succès');
        }).catch(function (error) {
          _this2.$message(error);
        }).then(function (_) {
          _this2.$loader(false);
        });
      }).catch(function (error) {
        if (error !== 'cancel') {
          console.log(error);

          _this2.$message(error);
        }
      });
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;