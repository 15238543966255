"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _SageList = _interopRequireDefault(require("@/components/SageList"));

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SageIndex',
  components: {
    SageList: _SageList.default
  },
  data: function data() {
    return {
      activeMenu: 'sage_ligne_1000',
      factureId: null
    };
  }
};
exports.default = _default;