var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Année " + _vm._s(_vm.year))]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", "margin-bottom": "15px" },
          attrs: { data: _vm.data, size: "mini", height: "calc(100vh - 590px)" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "num_affaire", label: "N° Affaire", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              scope.$index === 0 ||
                              (_vm.data[scope.$index - 1] &&
                                _vm.data[scope.$index].id !=
                                  _vm.data[scope.$index - 1].id),
                            expression:
                              "scope.$index === 0 || (data[scope.$index - 1] && data[scope.$index].id != data[scope.$index - 1].id)"
                          }
                        ],
                        attrs: { to: "/affaire/" + scope.row.id }
                      },
                      [
                        _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(_vm._s(scope.row.num_affaire))
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "designationDI",
              label: "Désignation DI",
              width: "200"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtBudgetInitial",
              label: "Budget initial",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm._f("toSumMt")(
                            scope.row.mtBudgetInitial,
                            "mtBudgetInitial",
                            _vm.sums
                          )
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtProvisions",
              label: "Aléas",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm._f("toSumMt")(
                            scope.row.mtProvisions,
                            "mtProvisions",
                            _vm.sums
                          )
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtBudgetMoisM",
              label: "Budget au mois M",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm._f("toSumMt")(
                            scope.row.mtBudgetMoisM,
                            "mtBudgetMoisM",
                            _vm.sums
                          )
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "% Avancement", width: "120", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("toPercentage")(
                            _vm._f("toSumPc")(
                              scope.row.mtBudgetMoisM !== 0
                                ? scope.row.mtBudgetDebloqueMoisM /
                                    scope.row.mtBudgetMoisM
                                : 0,
                              "pcAvancement",
                              "mtBudgetMoisM",
                              "mtBudgetDebloqueMoisM",
                              _vm.sums
                            )
                          )
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtBudgetDebloqueMoisM",
              label: "Budget débloqué à M",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm._f("toSumMt")(
                            scope.row.mtBudgetDebloqueMoisM,
                            "mtBudgetDebloqueMoisM",
                            _vm.sums
                          )
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtDepensesComptabilisees",
              label: "Dépenses comptabilisées",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm._f("toSumMt")(
                            scope.row.mtDepensesComptabilisees,
                            "mtDepensesComptabilisees",
                            _vm.sums
                          )
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtEngages",
              label: "Engagés",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("toCurrency")(
                          _vm._f("toSumMt")(
                            scope.row.mtEngages,
                            "mtEngages",
                            _vm.sums
                          )
                        )
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "mtSoldeBudget",
              label: "Solde budget",
              width: "120",
              align: "right"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm._f("toCurrency")(
                            _vm._f("toSumMt")(
                              scope.row.mtSoldeBudget,
                              "mtSoldeBudget",
                              _vm.sums
                            )
                          )
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }