"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _moment = _interopRequireDefault(require("moment"));

var _vue = _interopRequireDefault(require("vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableauAleas',
  props: ['data', 'date'],
  data: function data() {
    return {
      dspData: [],
      sums: []
    };
  },
  beforeMount: function beforeMount() {
    _moment.default.locale('fr');

    for (var data in this.data) {
      this.dspData.push(this.data[data]);
    }
  },
  computed: {
    libCol1: function libCol1() {
      var anneemois1 = (0, _moment.default)(this.date, 'YYYYMM').add('-1', 'year').format('YYYY');
      return 'Rappel des valeurs de Décembre ' + anneemois1;
    },
    libCol2: function libCol2() {
      var d = (0, _moment.default)(this.date, 'YYYYMM').add('-1', 'month');
      var y = (0, _moment.default)(d, 'YYYYMM').year();
      var m = (0, _moment.default)(d, 'YYYYMM').format('MMMM');
      return this.capitalizeFirstLetter(m) + ' ' + y;
    },
    libCol3: function libCol3() {
      var y = (0, _moment.default)(this.date, 'YYYYMM').year();
      var m = (0, _moment.default)(this.date, 'YYYYMM').format('MMMM');
      return this.capitalizeFirstLetter(m) + ' ' + y;
    }
  },
  filters: {
    toSumMt: function toSumMt(value, column, sums) {
      value = isNaN(value) ? 0 : Number(value);

      if (sums[column] === undefined) {
        sums[column] = Number(value);
      } else {
        sums[column] += Number(value);
      }

      return value;
    }
  },
  methods: {
    capitalizeFirstLetter: function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getSummaries: function getSummaries(param) {
      var _this = this;

      var columns = param.columns;
      var sums = [];
      columns.forEach(function (column, index) {
        /*
        if (index === 0) {
          sums[index] = `Nb affaires: ${this.dspData.length}`
          return
        }
        */
        if (index === 1) {
          sums[index] = 'TOTAL GENERAL';
          return;
        }

        if (column.property !== undefined && ![0, 1].includes(index)) {
          var value = _this.sums[column.property] ? _this.sums[column.property] : 0;

          if (column.property.substring(0, 2) === 'pc') {
            sums[index] = _vue.default.filter('toPercentage')(value);
          } else {
            sums[index] = _vue.default.filter('toCurrency')(value);
          }
        }
      });
      return sums;
    }
  }
};
exports.default = _default;