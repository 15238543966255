"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _vue = _interopRequireDefault(require("vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'TableauRapportDeboursesIndirects',
  props: ['data', 'year'],
  data: function data() {
    return {
      sums: [],
      pcs: []
    };
  },
  updated: function updated() {
    this.sums = [];
    this.pcs = [];
  },
  filters: {
    toSumMt: function toSumMt(value, column, aSums) {
      value = isNaN(value) ? 0 : Number(value);

      if (aSums[column] === undefined) {
        aSums[column] = Number(value);
      } else {
        aSums[column] += Number(value);
      }

      return value;
    },
    toSumPc: function toSumPc(value, column, col1, col2, aSums) {
      value = isNaN(value) ? 0 : Number(value);
      aSums['pcAvancement'] = aSums[col1] !== 0 ? aSums[col2] / aSums[col1] : 0; // console.log('toSumPc', value, column, col1, col2, aSums)

      return value;
    }
  },
  methods: {
    getSummaries: function getSummaries(param) {
      var _this = this;

      var columns = param.columns;
      var totSums = [];

      if (columns && columns.length > 0) {
        columns.forEach(function (column, index) {
          if (column.property !== undefined && ![0, 1].includes(index)) {
            // on exclue les colonnes à pas sommer
            var value = _this.sums[column.property] ? _this.sums[column.property] : 0;

            if (column.property.substring(0, 2) === 'pc') {
              totSums[index] = _vue.default.filter('toPercentage')(value);
            } else {
              totSums[index] = _vue.default.filter('toCurrency')(value);
            }
          }
        });
      }

      return totSums;
    }
  }
};
exports.default = _default;