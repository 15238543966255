import { render, staticRenderFns } from "./CommandeForm.vue?vue&type=template&id=18607a69&"
import script from "./CommandeForm.vue?vue&type=script&lang=js&"
export * from "./CommandeForm.vue?vue&type=script&lang=js&"
import style0 from "./CommandeForm.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/easy_dev/extranet/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18607a69')) {
      api.createRecord('18607a69', component.options)
    } else {
      api.reload('18607a69', component.options)
    }
    module.hot.accept("./CommandeForm.vue?vue&type=template&id=18607a69&", function () {
      api.rerender('18607a69', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/app/commandes/components/CommandeForm.vue"
export default component.exports