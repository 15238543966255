var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-scrollbar" }, [
    _c("div", {
      staticClass: "scroll-thumb",
      style: { width: _vm.thumbWidth + "px", left: _vm.thumbPosition + "px" },
      on: { mousedown: _vm.startDrag }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }