"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var state = {
  isAffaireRoute: false
};
var mutations = {
  setAffaireRoute: function setAffaireRoute(state, value) {
    state.isAffaireRoute = value; // Met à jour la variable globale
  }
};
var actions = {
  updateAffaireRoute: function updateAffaireRoute(_ref, value) {
    var commit = _ref.commit;
    commit('setAffaireRoute', value); // Action pour mettre à jour l'état
  }
};
var getters = {
  getAffaireRoute: function getAffaireRoute(state) {
    return state.isAffaireRoute;
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
exports.default = _default;