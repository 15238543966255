"use strict";

var _interopRequireDefault = require("/var/www/easy_dev/extranet/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.string.starts-with");

require("core-js/modules/es6.regexp.replace");

var _objectSpread2 = _interopRequireDefault(require("/var/www/easy_dev/extranet/node_modules/@babel/runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _eventBus = require("@/helpers/event-bus.js");

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  // 1. Effets de bord
  // --
  // 2. Informations globales
  // --
  name: 'CommandeForm',
  // 3. Type de composant
  // --
  // 4. Modificateurs de template
  // --
  // 5. Dépendances des templates
  // --
  // 6. Composition
  // --
  // 7. Interface (interface du composant)
  // --
  props: {
    action: {
      type: String,
      required: true,
      validator: function validator(value) {
        return ['new', 'edit'].includes(value);
      }
    },
    formData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true,
      validator: function validator(value) {
        return value.length > 0;
      }
    }
  },
  // 8. État local (propriétés réactives locales)
  // --
  data: function data() {
    return {
      rules: {
        date_commande: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        montant_initial: [{
          required: true,
          message: 'Champ obligatoire'
        }],
        numero: [{
          required: true,
          message: 'Champ obligatoire'
        }]
      },
      options: ['SIT', 'AV', 'DGD', 'FACT'],
      libCommande: {
        nom: '',
        code: '',
        libelle: ''
      }
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['affaire'])),
  // 9. Évènements
  // --
  mounted: function mounted() {
    if (this.isOldCodif()) {
      // pour gérer le format des anciens libellés sans les codes ['SIT', 'AV', 'DGD', 'FACT']
      this.libCommande.nom = this.nom();
      this.libCommande.code = '';
      this.libCommande.libelle = this.formData.libelle || '';
    } else {
      // on decoupe le libellé pour initialiser les zones écrans nom/code/libelle de la comande
      var nom = this.nom(); // recherche du nom de l'affaire à partir du num_affaire

      var code = '';
      var libelle = this.formData.libelle || '';

      if (this.formData.libelle !== '') {
        var libSansNom = libelle.replace(nom, '').trim();
        code = this.extractFirstPart(libSansNom).trim();
        libelle = this.extractLastPart(libSansNom, ' ', 1).trim();
        code = this.options.includes(code) ? code : '';
      }

      this.libCommande.nom = nom;
      this.libCommande.code = code;
      this.libCommande.libelle = libelle;
    }
  },
  // 10. Propriétés non réactives
  // --
  methods: (0, _objectSpread2.default)({}, (0, _vuex.mapActions)('facturation', ['insertCommande', 'updateCommande']), {
    isOldCodif: function isOldCodif() {
      var nom = this.nom();
      var libelle = this.formData.libelle || '';
      return libelle !== '' && !libelle.startsWith(nom);
    },
    // retourne le nom de l'affaire à partir du num_affaire
    nom: function nom() {
      switch (this.affaire.affaire.id_societe) {
        case 1:
          // PHM IMMO
          return this.extractLastPart(this.affaire.affaire.num_affaire, '-', 2);

        case 2:
          // CLUB SA
          return this.extractLastPart(this.affaire.affaire.num_affaire, '-', 2);

        case 3:
          // VISION HABITAT
          return this.extractLastPart(this.affaire.affaire.num_affaire, ' ', 1);

        case 4:
          // PHM INVEST
          return this.extractLastPart(this.affaire.affaire.num_affaire, '-', 1);
      }
    },
    libelle: function libelle() {
      var libelle = this.libCommande.libelle; // si on a sélectionné un code, on applique la nouvelle règle
      // pour constituer le libellé de la commande à la norme SAGE 1000

      if (this.options.includes(this.libCommande.code)) {
        libelle = "".concat(this.libCommande.nom, " ").concat(this.libCommande.code, " ").concat(this.libCommande.libelle);
      } // pas plus de 50 caractères. la taille le champ libelle de la table commande = 50 cars


      return libelle.substring(0, 50);
    },
    // retourne la première partie d'une chaîne, le séparateur = espace
    extractFirstPart: function extractFirstPart(input) {
      var index = input.indexOf(' ');

      if (index === -1) {
        return input;
      }

      return input.substring(0, index);
    },
    // retourne la fin d'une chaine à partir de l'occurence d'un séparateur
    extractLastPart: function extractLastPart(input, separator) {
      var separatorOccurrence = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      // Trouver l'index du séparateur en fonction de l'occurrence spécifiée
      var index = -1;
      var count = 0;

      for (var i = 0; i < input.length; i++) {
        if (input[i] === separator) {
          count++;

          if (count === separatorOccurrence) {
            index = i;
            break;
          }
        }
      }

      if (index === -1) {
        // Si le séparateur n'est pas trouvé
        return input;
      } // Retourner la partie après le séparateur


      return input.substring(index + 1);
    },
    resetForm: function resetForm() {
      this.$refs['ElForm'].resetFields();
    },
    validateForm: function validateForm() {
      var _this = this;

      this.$refs['ElForm'].validate(function (valid) {
        if (valid) {
          switch (_this.action) {
            case 'new':
              _this.actionNew();

              break;

            case 'edit':
              _this.actionEdit();

              break;
          }
        }
      });
    },
    actionNew: function actionNew() {
      var _this2 = this;

      var data = this.getFormData();
      this.$loader(true);
      var commande = data;
      console.log('[Commande/actionNew] 1.1. Création de la commande en BDD');
      this.insertCommande(commande).then(function () {
        console.log('[Commande/actionNew] 1.2. commande créée en BDD');

        _this2.$message('Création effectuée avec succès');

        _this2.resetForm();

        _this2.$loader(false);

        _eventBus.EventBus.$emit('COMMANDE_SAVE');
      }).catch(function (error) {
        _this2.$loader(false);

        _this2.$message(error);
      });
    },
    actionEdit: function actionEdit() {
      var _this3 = this;

      var data = this.getFormData();
      this.$loader(true);
      var commande = (0, _objectSpread2.default)({}, data, {
        id: this.formData.id
      });
      console.log('[Commande/actionEdit] 1.1. Modification de la commande ' + commande.id + ' en BDD');
      this.updateCommande(commande).then(function () {
        console.log('[Commande/actionEdit] 1.2. Commande ' + commande.id + ' modifiée en BDD');

        _this3.$message('Mise à jour effectuée avec succès');

        _this3.resetForm();

        _this3.$loader(false);

        console.log('[Commande/actionEdit] 2.3 emit event COMMANDE_SAVE');

        _eventBus.EventBus.$emit('COMMANDE_SAVE');
      }).catch(function (error) {
        _this3.$loader(false);

        _this3.$message(error);
      });
    },
    getFormData: function getFormData() {
      return {
        id_affaire: this.affaire.affaire.id,
        numero: this.formData.numero,
        date_commande: this.formData.date_commande,
        montant_initial: this.formData.montant_initial,
        avenant_1: this.formData.avenant_1,
        avenant_2: this.formData.avenant_2,
        avenant_3: this.formData.avenant_3,
        montant: this.formData.montant,
        emetteur: this.formData.emetteur,
        libelle: this.libelle(),
        // nouveau format du libellé de la commande pour une bonne intégration dans SAGE 1000 (evol 01/2024)
        commentaire: this.formData.commentaire
      };
    },
    handleMontantFinal: function handleMontantFinal() {
      var mtInitial = isNaN(this.formData.montant_initial) ? 0 : Number(this.formData.montant_initial);
      var mtAvenant1 = isNaN(this.formData.avenant_1) ? 0 : Number(this.formData.avenant_1);
      var mtAvenant2 = isNaN(this.formData.avenant_2) ? 0 : Number(this.formData.avenant_2);
      var mtAvenant3 = isNaN(this.formData.avenant_3) ? 0 : Number(this.formData.avenant_3);
      var mtFinal = mtInitial + mtAvenant1 + mtAvenant2 + mtAvenant3; // console.log('mtFinal', mtInitial, mtAvenant1, mtAvenant2, mtAvenant3, mtFinal)

      this.formData.montant = mtFinal;
    }
  }) // 11. Rendu
  // --

};
exports.default = _default;