var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "affaire-list" },
    [
      _c("h2", [_vm._v("Liste des affaires")]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-row",
            { attrs: { id: "anchor-table-site" } },
            [
              _c(
                "el-col",
                { attrs: { span: 24, align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        icon: "el-icon-d-arrow-right",
                        type: "primary",
                        size: "mini",
                        loading: _vm.btnExportLoading.excel,
                        disabled: _vm.total === 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleExport("excel")
                        }
                      }
                    },
                    [_vm._v("\n          Export Excel\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "StickyTable",
        {
          attrs: {
            classIdent: ".affaire-list",
            tableClass: ".dataTable",
            top: 50,
            closeWidth: 174,
            openWidth: 330
          }
        },
        [
          _c(
            "data-tables",
            {
              ref: "dataTable",
              staticClass: "dataTable",
              attrs: {
                data: _vm.data,
                "current-page": _vm.currentPage,
                "page-size": _vm.pageSize,
                total: _vm.total,
                "pagination-props": { pageSizes: [100, 250, 500] },
                "table-props": { size: "mini" },
                "default-sort": { prop: "num_affaire", order: "ascending" },
                layout: "table, pagination"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "N° affaire",
                  width: "350",
                  prop: "num_affaire",
                  sortable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "router-link",
                          { attrs: { to: "/affaire/" + scope.row.id } },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _vm._v(_vm._s(scope.row.num_affaire))
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Nom affaire",
                  width: "300",
                  prop: "nom",
                  sortable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(_vm._s(scope.row.nom))]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Client",
                  width: "250",
                  prop: "client",
                  sortable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            (_vm.data[scope.$index].client = _vm.client(
                              scope.row.id_client
                            ))
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Enseigne",
                  width: "350",
                  prop: "enseigne",
                  sortable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            (_vm.data[scope.$index].enseigne = _vm.enseigne(
                              scope.row.id_enseigne
                            ))
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "Secteur géo",
                  width: "175",
                  prop: "secteurGeo",
                  sortable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            (_vm.data[scope.$index].secteurGeo = _vm.secteurGeo(
                              scope.row.id_secteur_geo
                            ))
                          )
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  width: "150",
                  "class-name": "right"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.manager === "facture"
                          ? [
                              _c(
                                "el-dropdown",
                                {
                                  attrs: { size: "mini" },
                                  on: { command: _vm.handleCommand }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "el-dropdown-link",
                                      staticStyle: { color: "#409EFF" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v("\n            Actions "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right"
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown"
                                    },
                                    [
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            icon: "el-icon-circle-plus",
                                            command: {
                                              action: "facture",
                                              index: scope.$index,
                                              row: scope.row
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Ajouter une facture\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-dropdown-item",
                                        {
                                          attrs: {
                                            icon: "el-icon-money",
                                            divided: "",
                                            command: {
                                              action: "reglements",
                                              index: scope.$index,
                                              row: scope.row
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                Gérer les factures\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm.manager === "commande"
                          ? [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "margin-top": "-8px"
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleManageLink(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Gérer les commandes\n          "
                                  )
                                ]
                              )
                            ]
                          : [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "margin-top": "-8px"
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.handleManageLink(
                                        scope.$index,
                                        scope.row
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Sélectionner l'affaire\n          "
                                  )
                                ]
                              )
                            ]
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("Pagination", {
        staticStyle: { "margin-bottom": "10px" },
        attrs: {
          pageIdent: _vm.manager + "Affaires",
          "current-page": _vm.currentPage,
          "page-size": _vm.pageSize,
          total: _vm.total
        },
        on: {
          "update:currentPage": function($event) {
            _vm.currentPage = $event
          },
          "update:current-page": function($event) {
            _vm.currentPage = $event
          },
          "update:pageSize": function($event) {
            _vm.pageSize = $event
          },
          "update:page-size": function($event) {
            _vm.pageSize = $event
          }
        }
      }),
      _vm._v(" "),
      _c("ScrollBar")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }